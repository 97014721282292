<template>
    <div data-scroll data-scroll-id="inner-header" class="small-header">
        <div class="background-image">
            <img :src="coverImage"
                 class="story-image" alt="story">
            <grain-effect/>
        </div>
    </div>
</template>


<script>
import GrainEffect from '@/components/custom/GrainEffect'
import {mapGetters} from "vuex";

export default {
    components: {
        GrainEffect,
    },
    computed: {
        ...mapGetters({
            getCurrentPageMenuData: 'menu/getCurrentPageMenuData',
            device: 'resize/geDevice',
        }),
        coverImage() {
            return this.getCurrentPageMenuData?.media?.cover?.devices?.[this.device?.type]
        },
    },

}
</script>


<style lang="scss" scoped>
.small-header {
    position: relative;
    width: 105%;
    left: -2.5%;
    height: 268px;
    top: 0;
    overflow: hidden;
    @media only screen and (max-width: 1024px) and (min-width: 769px) {
        height: 380px;
    }

    @media only screen and (max-width: 768px) {
        height: 320px;
    }

    .background-image {
        position: absolute;
        height: 100%;
        width: 100%;

        .story-image {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
}
</style>
